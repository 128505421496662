import React, { useState, useEffect } from 'react';
import './TailoredMindfulness.css';
import { IoIosReturnLeft } from "react-icons/io";

import LoadingAnimation from './LoadingAnimation';  // Import the LoadingAnimation component
const fetchCsrfToken = async () => {
    const response = await fetch('/api/get-csrf/');
    const data = await response.json();
    return data.csrfToken;
};

const languagePlaceholders = [
'Enter topic for the meditation session',
'输入冥想会话的主题',
'ध्यान सत्र के लिए विषय दर्ज करें',
'Introduce el tema para la sesión de meditación',
'Entrez le sujet pour la session de méditation',
'أدخل موضوع جلسة التأمل',
'ধ্যান সেশনের জন্য বিষয় লিখুন',
'Insira o tópico para a sessão de meditação',
'Введите тему для сеанса медитации',
'مراقبے کے سیشن کے لیے موضوع درج کریں',
'Masukkan topik untuk sesi meditasi',
'瞑想セッションのトピックを入力してください',
'Geben Sie das Thema für die Meditationssitzung ein',
'Ingiza mada kwa ajili ya kipindi cha kutafakari',
'ध्यान सत्रासाठी विषय प्रविष्ट करा',
'ధ్యానం సెషన్ కోసం విషయం నమోదు చేయండి',
'Meditasyon oturumu için konuyu girin',
'தியான அமர்வுக்கான தலைப்பை உள்ளிடவும்',
'명상 세션의 주제를 입력하세요',
'Inserisci l\'argomento per la sessione di meditazione',


];


const TailoredMindfulness = () => {
  const [text, setText] = useState('');
  const [modelResponse, setModelResponse] = useState('');
  const [audioSrc, setAudioSrc] = useState('');  // State for audio source
  const [isLoading, setIsLoading] = useState(false);  // State for loading
  const [duration, setDuration] = useState(8);  // State for session duration with a default value
  const [userInfo, setUserInfo] = useState(null);  // State for user info (null when not logged in)
  const [showModal, setShowModal] = useState(false); //
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const [isSignUpVisible, setIsSignUpVisible] = useState(false); // For the "Sign Up" popup
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [displayText, setDisplayText] = useState(languagePlaceholders[0]);
  const [isFading, setIsFading] = useState(false);
  const [isSessionGenerated, setIsSessionGenerated] = useState(false);

useEffect(() => {
  if (text.trim() !== '') return; // Stop language rotation if user is typing

  let index = 0;
  const interval = setInterval(() => {
    setIsFading(true); // Trigger fade-out
    setTimeout(() => {
      index = (index + 1) % languagePlaceholders.length;
      setDisplayText(languagePlaceholders[index]); // Update placeholder text
      setIsFading(false); // Trigger fade-in
    }, 300); // Duration of fade-out
  }, 3000); // Change every 3 seconds

  return () => clearInterval(interval); // Cleanup on unmount
}, [text]);

	// Suggested topics for the meditation
  const suggestions = [ "Sleep", "Gratitude", "Focus", "Stress Relief", "Body-scan" ];

  // Check if the user is logged in (for example, you can fetch user info from the server)
  useEffect(() => {
    const fetchUserInfo = async () => {
      const response = await fetch('/api/get-user-info');
      const data = await response.json();
      if (data.username) {
        setUserInfo(data);  // Set user info if logged in
      }
    };

    fetchUserInfo();
  }, []);

  const handleInputChange = (e) => {
    setText(e.target.value);
    setDisplayText(''); // Ensure the placeholder disappears

  };

  const handleSuggestionClick = (suggestion) => {
    setText(suggestion);  // Update input with the suggestion text
  setDisplayText(''); // Ensure the placeholder disappears

  };

  const handleDurationChange = async (e) => {
    setDuration(e.target.value);
    await fetch(`/update-session-duration/?duration=${encodeURIComponent(e.target.value)}`);
  };


  const handlePlayButtonClick = async () => {
    
    setIsLoading(true);  // Start loading
    const response = await fetch(`/get-text-response?text=${encodeURIComponent(text)}&duration=${encodeURIComponent(duration)}`);
    const data = await response.json();
    setModelResponse(data.response);

    // Set the audio source after getting the model response
    const audioUrl = `/stream-silence-mp3?text=${encodeURIComponent(data.response)}&duration=${encodeURIComponent(duration)}`;
    setAudioSrc(audioUrl);

    setIsLoading(false);  // Stop loading
  };

  const handleGoogleLogin = () => {
    window.location.href = '/accounts/google/login/';
  };



  const toggleLoginPopup = () => {
	    setIsLoginVisible((prev) => !prev);
  };
  

  const toggleSignUpPopup = () => {
    setIsSignUpVisible((prev) => !prev);
  };

  const showSignUpPopup = () => {
    setIsLoginVisible(false);
    setIsSignUpVisible(true);
  };



 const handleGenerateSession = async () => {
        setIsLoading(true);
        const response = await fetch(`/get-text-response?text=${encodeURIComponent(text)}&duration=${encodeURIComponent(duration)}`);
        const data = await response.json();

        const audioUrl = `/stream-silence-mp3?text=${encodeURIComponent(data.response)}&duration=${encodeURIComponent(duration)}`;
        setAudioSrc(audioUrl);

        setIsLoading(false);
        setIsSessionGenerated(true); // Mark the session as generated
    };

    const handleRestoreTools = () => {
        setText('');
        setAudioSrc('');
        setIsSessionGenerated(false); // Reset the session
    };

return (
    <div className="container">
        <h1 className="title">Tailored Meditation Session</h1>
	<input
	  type="text"
	  value={text} // Only display user input
	  placeholder={displayText} // Use placeholder for rotating text
	  onFocus={() => {
	    setText(''); // Clear user input
	    setDisplayText(''); // Clear placeholder text
	  }}
	  onChange={handleInputChange} // Handle user input
	  className={`input ${text.trim() === '' && isFading ? 'fading' : ''}`} // Apply fading only when placeholder is active
	/>

    {!isSessionGenerated ? (
    <>
        <div className="slider-container">
            <label className="label">Session Duration: {duration} minutes</label>
            <input
                type="range"
                min="1"
                max="12"
                value={duration}
                onChange={handleDurationChange}
                className="slider"
            />
        </div>
        <div className="suggestion-container">
            {suggestions.map((suggestion, index) => (
                <button
                    key={index}
                    className="suggestion-button"
                    onClick={() => handleSuggestionClick(suggestion)}
                >
                    {suggestion}
                </button>
            ))}
        </div>
        {isLoading ? (
            <LoadingAnimation /> // Show loading animation
        ) : (
            <button
                className="generate-button"
                onClick={handleGenerateSession}
                disabled={isLoading}
            >
                Generate Meditation
            </button>
        )}
    </>
) : (
    <>
        <audio controls className="audio">
            <source src={audioSrc} type="audio/wav" />
            Your browser does not support the audio element.
        </audio>
        <div className="suggestion-container">
            <button
                className="icon-button"
                onClick={handleRestoreTools}
                title="Return to beginning"
            >
                <IoIosReturnLeft />
            </button>
        </div>
    </>
)}



	</div>

  );
};

export default TailoredMindfulness;
